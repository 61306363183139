import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome! <span role={"img"} aria-label={"jsx-a11y/accessible-emoji"}>😀</span></h1>
        <img src={"dog.png"} alt={"A very cute dog"}/>
        <p>
          My name is Charis Schille. I'm a Computer Science student at
          Østfold University College! I spend most of my free time
          listening to music or playing video games.
        </p>
        <a className={"App-link"} href={"https://gitlab.com/haros"}>Gitlab - @haros</a>
        <a className={"App-link"} href={"https://www.linkedin.com/in/charis-schille/"}>LinkedIn - @charis-schille</a>
        <a className={"App-link"} href={"mailto:kleopi.dev@gmail.com"}>Email - kleopi.dev@gmail.com</a>
      </header>
    </div>
  );
}

export default App;
